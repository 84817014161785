@keyframes bgFadeIn {
  0% {opacity:0;}
  100% {opacity:0.5;}
}
  
@-moz-keyframes bgFadeIn {
  0% {opacity:0;}
  100% {opacity:0.5;}
}
  
@-webkit-keyframes bgFadeIn {
  0% {opacity:0;}
  100% {opacity:0.5;}
}
  
@-o-keyframes bgFadeIn {
  0% {opacity:0;}
  100% {opacity:0.5;}
}
  
@-ms-keyframes bgFadeIn {
  0% {opacity:0;}
  100% {opacity:0.5;}
}

body {
  margin: 0px;
  font-family: 'Trebuchet MS', sans-serif;
  overscroll-behavior: none;
  background-color: #101010;
}

.app-content {
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(#181818, #101010);
}

.home-bg-topo {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.5;
  object-fit: cover;
  animation: bgFadeIn linear 0.5s;
  -webkit-animation: bgFadeIn linear 0.5s;
  -moz-animation: bgFadeIn linear 0.5s;
  -o-animation: bgFadeIn linear 0.5s;
  -ms-animation: bgFadeIn linear 0.5s;
}