@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
  
@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
  
@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
  
@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
  
@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@keyframes homeFadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}
  
@-moz-keyframes homeFadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}
  
@-webkit-keyframes homeFadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}
  
@-o-keyframes homeFadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}
  
@-ms-keyframes homeFadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}

.home-page-content {
  position: absolute;
  overflow: hidden;
  width: 100%;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home-page-content.exiting {
  animation: homeFadeOut linear 0.5s;
  -webkit-animation: homeFadeOut linear 0.5s;
  -moz-animation: homeFadeOut linear 0.5s;
  -o-animation: homeFadeOut linear 0.5s;
  -ms-animation: homeFadeOut linear 0.5s;
}

.home-link {
  font-family: 'Trebuchet MS';
  font-size: 2em;
  font-weight: bolder;
  text-align: center;
  text-decoration: none;
  padding: 0.3em;
  opacity: 0;
  color: white;
  z-index: 999;
  animation: fadeIn linear 0.5s 0.5s forwards;
  -webkit-animation: fadeIn linear 0.5s 0.5s forwards;
  -webkit-animation: fadeIn linear 0.5s 0.5s forwards;
  -moz-animation: fadeIn linear 0.5s 0.5s forwards;
  -o-animation: fadeIn linear 0.5s 0.5s forwards;
  -ms-animation: fadeIn linear 0.5s 0.5s forwards;
}

.navigation {
  z-index: 999;
}

.link {
  font-family: 'Trebuchet MS';
  font-size: 0.75em;
  font-weight: bolder;
  text-align: center;
  text-decoration: none;
  padding: 0.75em;
  opacity: 0;
  color: white;
  background-color: rgba(0,0,0,0);
  border: none;
  outline: none;
  z-index: 999;
  animation: fadeIn linear 0.5s 0.5s forwards;
  -webkit-animation: fadeIn linear 0.5s 0.5s forwards;
  -moz-animation: fadeIn linear 0.5s 0.5s forwards;
  -o-animation: fadeIn linear 0.5s 0.5s forwards;
  -ms-animation: fadeIn linear 0.5s 0.5s forwards;
}

@media only screen and (min-width: 480px) {
  /* Style adjustments for viewports 480px and over go here */

  .home-link {
    font-size: 2.5em;
  }
  
  .link {
    font-size: 1em;
    padding: 0.75em;
  }
}

@media only screen and (min-width: 548px) {
  /* Style adjustments for viewports 768px and over go here */

  .home-link {
    font-size: 3em;
  }
  
  .link {
    font-size: 1.25em;
    padding: 0.75em;
  }
}

@media only screen and (min-width: 768px) {
  /* Style adjustments for viewports 768px and over go here */

  .home-link {
    font-size: 4em;
  }
  
  .link {
    font-size: 1.6em;
    padding: 0.75em;
  }
}

@media only screen and (min-width: 896px) {
  /* Style adjustments for viewports 768px and over go here */

  .home-link {
    font-size: 5em;
  }
  
  .link {
    font-size: 2em;
    padding: 0.75em;
  }
}