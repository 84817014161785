@keyframes navFadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
  
@-moz-keyframes navFadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
  
@-webkit-keyframes navFadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
  
@-o-keyframes navFadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
  
@-ms-keyframes navFadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@keyframes navFadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}
  
@-moz-keyframes navFadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}
  
@-webkit-keyframes navFadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}
  
@-o-keyframes navFadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}
  
@-ms-keyframes navFadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}

.navbar {
  position: absolute;
  width: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  /* background-image: linear-gradient(#181818AA, transparent); */
  background-color: rgba(92,77,56,0.9); /* #5c4d38; */
  animation: navFadeIn linear 0.5s;
  -webkit-animation: navFadeIn linear 0.5s;
  -moz-animation: navFadeIn linear 0.5s;
  -o-animation: navFadeIn linear 0.5s;
  -ms-animation: navFadeIn linear 0.5s;
}

.navbar.exiting {
  animation: navFadeOut linear 0.5s;
  -webkit-animation: navFadeOut linear 0.5s;
  -moz-animation: navFadeOut linear 0.5s;
  -o-animation: navFadeOut linear 0.5s;
  -ms-animation: navFadeOut linear 0.5s;
}

.navbar-link {
  font-family: 'Trebuchet MS';
  font-size: 1.25em;
  font-weight: bolder;
  text-align: center;
  text-decoration: none;
  padding: 0.5em;
  color: white;
  z-index: 999;
}

#logo-button {
  border: 1px solid white;
  background-color: rgba(0,0,0,0);
  outline: none;
  cursor: pointer;
}

@media only screen and (min-width: 624px) {
  /* Style adjustments for viewports 768px and over go here */

  .navbar-link {
    font-size: 1.25em;
    padding: 0.75em;
  }
}

@media only screen and (min-width: 768px) {
  /* Style adjustments for viewports 768px and over go here */

  .navbar-link {
    font-size: 1.5em;
  }
}

@media only screen and (min-width: 896px) {
  /* Style adjustments for viewports 768px and over go here */

  .navbar-link {
    font-size: 1.75em;
  }
}

@media only screen and (min-width: 1024px) {
  /* Style adjustments for viewports 768px and over go here */

  .navbar-link {
    font-size: 2em;
  }
}