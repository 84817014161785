@keyframes heroFadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
  
@-moz-keyframes heroFadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
  
@-webkit-keyframes heroFadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
  
@-o-keyframes heroFadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
  
@-ms-keyframes heroFadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@keyframes heroFadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}
  
@-moz-keyframes heroFadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}
  
@-webkit-keyframes heroFadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}
  
@-o-keyframes heroFadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}
  
@-ms-keyframes heroFadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}

.about-page-content {
  position: absolute;
  overflow: scroll;
  width: 100%;
  height: 100vh;
  height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
}

.about-me-hero {
  width: 100%;
  height: 80vh;
  object-fit: cover;

  animation: heroFadeIn linear 0.5s;
  -webkit-animation: heroFadeIn linear 0.5s;
  -moz-animation: heroFadeIn linear 0.5s;
  -o-animation: heroFadeIn linear 0.5s;
  -ms-animation: heroFadeIn linear 0.5s;
}